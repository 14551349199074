// theme.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {ThemeModel} from "../models/theme.model";


@Injectable({
    providedIn: 'root'
})
export class ThemeService {
    private themeSubject = new BehaviorSubject<ThemeModel | null>(null);

    theme$: Observable<ThemeModel | null> = this.themeSubject.asObservable();
    setTheme(theme: ThemeModel): void {
        this.themeSubject.next(theme);
    }
}

