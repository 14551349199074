@if(barsSubject$ | async; as bars ){
    @for (bar of bars; track bar) {
        <div class="bar"
             [ngStyle]="{
                 'left': bar.left,
                 'background-color': bar.backgroundColor,
                 'height': bar.height,
                 'animation-duration': bar.animationDuration,
                 'filter': bar.filter
                 }"></div>
    }
}
