import {Component, OnInit} from '@angular/core';
import {BarModel} from "../../models/bar.model";
import {BehaviorSubject, interval, Subscription, take} from "rxjs";
import {AsyncPipe, NgStyle} from "@angular/common";
import {ThemeService} from "../../services/theme.service";
import {ThemeModel} from "../../models/theme.model";

@Component({
  selector: 'app-background',
  standalone: true,
    imports: [
        AsyncPipe,
        NgStyle
    ],
  templateUrl: './background.component.html',
  styleUrl: './background.component.scss'
})
export class BackgroundComponent implements OnInit {

    private subscriptions = new Subscription();

    constructor(private themeService: ThemeService) {}

    bars: BarModel[] = [];
    barsSubject$: BehaviorSubject<BarModel[]> = new BehaviorSubject<BarModel[]>(this.bars);
    theme: ThemeModel | null = null;

    private config = {
        pxWidthForMobile: 768, // This decides the breakpoint in px for when the background is to be adjusted for mobile
        nrOfBarsPC: 150, // Amount of bars in total to be generated to be viewed on PC
        intervalPC: 100, // How often it should generate bars for PC in ms (larger = more scattered bars, smaller = more densely placed bars)
        nrOfBarsMobile: 75, // Amount of bars in total to be generated to be viewed on mobile
        intervalMobile: 200, // How often it should generate bars for mobile in ms (larger = more scattered bars, smaller = more densely placed bars)

        barHeightMax: 50, // The maximum height of the bars in px
        barHeightMin: 25, // The minimum height of the bars in px
        barAnimationDurationMax: 22, // The maximum animation duration of the bars in s (lower = faster bars, higher = slower bars)
        barAnimationDurationMin: 12 // The minimum animation duration of the bars in s (lower = faster bars, higher = slower bars)
    }

    ngOnInit() {
        this.decideNumberOfBars(0);
        this.subscriptions.add(
            this.themeService.theme$.subscribe((theme: ThemeModel | null) => {
                if (theme) {
                    this.theme = theme;
                }
            })
        );
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    decideNumberOfBars(iteration: number) {
        let numberOfBars: number = this.config.nrOfBarsPC; // Defaults to PC values
        let intervalPeriod: number = this.config.intervalPC;

        if(iteration >= 20) { // Since the function is recursive, I added an upper boundary. Sometimes 'window' isn't read properly
            this.generateBars(numberOfBars, intervalPeriod);
            return;
        }

        if (typeof window !== 'undefined') { // Sets the amount of bars and the interval at which they will be generated, based on if it's viewed from PC or mobile
            numberOfBars = window.innerWidth > this.config.pxWidthForMobile ? this.config.nrOfBarsPC : this.config.nrOfBarsMobile;
            intervalPeriod = window.innerWidth > this.config.pxWidthForMobile  ? this.config.intervalPC : this.config.intervalMobile;
        } else {
            this.decideNumberOfBars(iteration + 1); // If it failed to read 'window' properly, start the recursion and add 1 to the iteration
        }

        this.generateBars(numberOfBars, intervalPeriod);
    }

    generateBars(numberOfBars: number, intervalPeriod: number) {

        interval(intervalPeriod) // Emit a value every intervalPeriod
            .pipe(take(numberOfBars)) // Only take the first numBars values (donno what that means really)
            .subscribe(() => {
                let windowWidth = typeof window == 'undefined' ? 1080 : window.innerWidth

                let newBar: BarModel = { // Sets random values to the individual bars based on config.
                    height: `${Math.floor(Math.random() * (this.config.barHeightMax - this.config.barHeightMin + 1)) + this.config.barHeightMin}px`,
                    left: `${Math.floor(Math.random() * windowWidth) + 10}px`,
                    backgroundColor: this.getRandomColor(),
                    animationDuration: `${Math.floor(Math.random() * (this.config.barAnimationDurationMax - this.config.barAnimationDurationMin + 1)) + this.config.barAnimationDurationMin}s`,
                    filter: `blur(${Math.floor(Math.random() * 5)}px)`
                };

                this.bars.push(newBar);
                this.barsSubject$.next([... this.bars]); // Emit the new bars array
            });
    }

    getRandomColor() {
        let colors: string[];
        if (this.theme && this.theme.barColors !== undefined) {
            colors = this.theme.barColors.split(';');
        } else {
            colors = ['#F58025', '#01437E', '#0079AD', '#67BAAF', '#FC403F', '#01437E'];
        }
        return colors[Math.floor(Math.random() * colors.length)];
    }

}
